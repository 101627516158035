import React, { Component } from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"
import Bitty_logo from "../../images/diamond-designs/dd-logo.png"
import Approch from "../../images/diamond-designs/ddu-client.png"
import ads_01 from "../../images/diamond-designs/ads-img-01.png"
import ads_02 from "../../images/diamond-designs/ads-img-02.png"
import ads_03 from "../../images/diamond-designs/ads-img-03.png"
import ads_04 from "../../images/diamond-designs/ads-img-04.png"
import ads_05 from "../../images/diamond-designs/ads-img-05.png"

import Scroll_top_img from "../../images/scroll-arrow.png"
import {Helmet} from "react-helmet"

import { Link } from "gatsby";
import Calltoaction from "../../components/Call-to-action"

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ''
        }
    }

    componentDidMount() {
        this.setState({
            url: document.location.href
        });
    }
    render() {
        return (
            <Layout>
                <Helmet>
                 <title>Case Study for Google Shopping Engines - Diamond Designs</title>
                    <meta name="description"
                        content="The SEO case study on increasing the organic sessions of buyer-focused blog posts by 106.94%. Merging & improving the right blog posts helped achieve the results." />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <link rel="canonical" href={this.state.url} />

                    <meta property="og:site_name" content="CueForGood" />
                    <meta property="fb:app_id" content="289086684439915" />
                    <meta property="og:url" content={this.state.url} />
                    <meta property="og:title"
                        content="Case Study for Google Shopping Engines - Diamond Designs" />
                    <meta property="og:description"
                        content="The SEO case study on increasing the organic sessions of buyer-focused blog posts by 106.94%. Merging & improving the right blog posts helped achieve the results." />
                    <meta property="og:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description"
                        content="The SEO case study on increasing the organic sessions of buyer-focused blog posts by 106.94%. Merging & improving the right blog posts helped achieve the results." />
                    <meta name="twitter:title"
                        content="Case Study for Google Shopping Engines - Diamond Designs" />
                    <meta name="twitter:site" content="@CueForGood" />
                    <meta name="twitter:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                </Helmet>
               <div className="cb_expertiseInner">
                  <div className="case_study_page ddu ddu-casestudy">
                     <div className="case-wrap  ">
                           <div className="ww-sec-one ">
                              <div className="container">
                                 <h3>Case Study</h3>
                                 <h1><img className="ddu_logo" src={Bitty_logo} alt="Diamond Designs logo" /></h1>
                                 <p className="ddu_p">How CueForGood helped DiamondDesigns run Facebook and Instagram ads from scratch to boost sales in Ireland, achieving 5x Return On Ad Spend (ROAS) in merely 5 months. </p>
                                 <div className="revenue-ddu">
                                    <div className="revenue-inn">
                                       <div className="revenue-single circle1">
                                          <div className="revenue-single-outer">
                                             <div className="progress blue1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 60.32%</div>
                                             </div>
                                          </div>
                                          <p>Increase <br/>In Reach</p>
                                       </div>
                                       <div className="revenue-single circle2">
                                          <div className="revenue-single-outer">
                                             <div className="progress blue1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 94.20%</div>
                                             </div>
                                          </div>
                                          <p>Increase <br/>In Conversions</p>
                                       </div>
                                       <div className="ddu_5cx">
                                          <b>5x</b>
                                          <span><strong>Overall <br/>ROAS</strong></span>
                                       </div>
                                       <div className="revenue-single circle3">
                                          <div className="revenue-single-outer">
                                             <div className="progress ddu1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 61.54%</div>
                                             </div>
                                          </div>
                                          <p>Increase <br/>In Link Clicks</p>
                                       </div>
                                       <div className="revenue-single circle4">
                                          <div className="revenue-single-outer">
                                             <div className="progress green1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 116.18%</div>
                                             </div>
                                          </div>
                                          <p>Increase <br/>In Impressions</p>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="ddu-increase-revenue">
                                <p className="note"><strong>NOTE:</strong> The results have been tracked from April 2021 to August 2021.</p>
                                 </div>
                              </div>
                           </div>
                     </div>

                     <div className="cb_good_section call-action-dimaonddesign">
                        <Calltoaction sid="ansel1"/>
                     </div>

                    <div className="ddu-approach">
                       <div className="container">
                        <div className="approach-upp">
                             <div className="approach-upp-right">
                                <img src={Approch} alt="Diamond Designs Approach" />
                             </div>
                             <div className="approach-upp-left">
                                <h2 className="heading_main">About the Client </h2>
                                <p>DiamondDesigns is an eCommerce store that offers unique medical and beauty uniforms. Their comprehensive collection involves a full range of functional yet smart workwear for the salon and healthcare industry. </p>
                             </div>
                        </div>
                       </div>
                    </div>

                  <div className="ddu-background-col">
                     <div className="container">
                        <h2>Background</h2>
                        <p>DiamondDesigns, in pursuit of scaling its eCommerce business efficiently, decided to explore the power of social media marketing. To begin with, we suggested running paid campaigns across Facebook and Instagram</p>
                     </div>
                  </div>
                  <div className="dd-challenges_row">
                     <div className="container">
                        <div className="dd-challenges">
                           <h2>Challenges</h2>
                           <ul>
                              <li>
                                 <span><strong>1</strong></span>
                                 <h3>Competitive niche</h3>
                                 <p>Because salon and healthcare workwear is an industry that is fairly oversaturated in Ireland, standing out was a challenge.</p>
                              </li>
                              <li>
                                 <span><strong>2</strong></span>
                                 <h3>Limited audience interests</h3>
                                 <p>Finding interests related to the niche was a hard nut to crack since we only had a handful of them to target at our disposal.</p>
                              </li>
                              <li>
                                 <span><strong>3</strong></span>
                                 <h3>Small target location</h3>
                                 <p>Since Ireland is a relatively smaller market and the competition for this niche was high, it posed an issue.</p>
                              </li>
                              <li>
                                 <span><strong>4</strong></span>
                                 <h3>iOS 14 update</h3>
                                 <p>Since the iOS 14 update allows users to opt-out of having their activity tracked, it prevents marketers (like us) from sending personalized advertising to those who opt-out. This was a challenge as tracking conversions became difficult and so did optimising the ads.</p>
                              </li>
                           </ul>
                        </div>

                        <div className="dd-challenges">
                           <h2>What We Did</h2>
                           <ul>
                              <li>
                                 <span><strong>1</strong></span>
                                 <h3>Understood the client requirements & looked for scope</h3>
                                 <p>After understanding the breadth and depth of the client’s requirements, we evaluated whether Facebook and Instagram ads will prove beneficial for the business and how. Being an oversaturated industry already, we did a competitor analysis and that helped us build a failproof strategy.</p>
                              </li>
                              <li>
                                 <span><strong>2</strong></span>
                                 <h3>Elevated user engagement </h3>
                                 <p>The data from our research revealed that the brand searches for DiamondDesigns were low. Consequently, the first step was to raise brand awareness which is why we chose to enhance user engagement by boosting potential posts. </p>
                              </li>
                              <li>
                                 <span><strong>3</strong></span>
                                 <h3>Filtered relevant audience</h3>
                                 <p>Target audiences can be segmented into categories like interest, demographics, location, etc. We targeted the interests of our relevant audience that included nurses, healthcare professionals, salon workers, and more. After starting with broader interests, the target audience was narrowed down further.</p>
                              </li>
                              <li>
                                 <span><strong>4</strong></span>
                                 <h3>Expanded the Ad targeting</h3>
                                 <p>To scale ad targeting, we built a lookalike audience which is an audience that is most likely to bring business because it is similar to the best existing customers.</p>
                              </li>
                              <li>
                                 <span><strong>5</strong></span>
                                 <h3>Accentuated brand loyalty</h3>
                                 <p>Since remarketing ads have higher chances of conversions, we built remarketing and dynamic remarketing audiences to attract users who had previously engaged with the business. This fostered trust and promoted the audience with purchase intent signals in the past to convert.</p>
                              </li>
                              <li>
                                 <span><strong>6</strong></span>
                                 <h3>Experimented with the creatives</h3>
                                 <p>Because user engagement with the ads is very important, we kept experimenting with new creatives and videos to attract customers. Right from testimonial ads to product-centric ads, we tried them all to know what works best and leveraged the same.</p>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="ads-container">
                     <div className="container">
                        <h2>Ads & Audiences Used</h2>
                        <ul>
                           <li>
                           <img src={ads_01} alt="Diamond DesignsApproach" />
                              <p>Dynamic<br />
Ads</p>
                           </li>
                           <li>
                           <img src={ads_02} alt="Diamond DesignsApproach" />
                              <p>Image<br/>
Ads</p>
                           </li>
                           <li>
                           <img src={ads_03} alt="Diamond DesignsApproach" />
                              <p>Video<br/>
Ads</p>
                           </li>
                           <li>
                           <img src={ads_04} alt="Diamond DesignsApproach" />
                           <p>Custom<br/>
Audiences</p>
                           </li>
                           <li>
                           <img src={ads_05} alt="Diamond DesignsApproach" />
                          <p> Lookalike<br/>
Audiences</p>
                           </li>
                        </ul>
                     </div>
                  </div>
                 
                  <div className="cb_good_section call-action-component">
                        <div className="service_weoffer cb_testimonails">
                        <Calltoaction sid="diamond2"/>
                        </div>
                     </div>
                 
                  <div className="revenue-ddu-bottom">
                  <div className="revenue-ddu">
                                    <div className="revenue-inn">
                                       <div className="revenue-single circle1">
                                          <div className="revenue-single-outer">
                                             <div className="progress ddu1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 60.32%</div>
                                             </div>
                                          </div>
                                          <p>Increase <br/>In Reach</p>
                                       </div>
                                       <div className="revenue-single circle2">
                                          <div className="revenue-single-outer">
                                             <div className="progress blue1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 94.20%</div>
                                             </div>
                                          </div>
                                          <p>Increase<br/>In Conversions</p>
                                       </div>
                                       <div className="ddu_5cx">
                                          <b>5x</b>
                                          <span><strong>Overall <br/>ROAS</strong></span> 
                                       </div>
                                       <div className="revenue-single circle3">
                                          <div className="revenue-single-outer">
                                             <div className="progress pink1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 61.54%</div>
                                             </div>
                                          </div>
                                          <p>Increase <br/>In Link Clicks</p>
                                       </div>
                                       <div className="revenue-single circle4">
                                          <div className="revenue-single-outer">
                                             <div className="progress green1">
                                                <span className="progress-left">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <span className="progress-right">
                                                   <span className="progress-bar"></span>
                                                </span>
                                                <div className="progress-value"> 116.18%</div>
                                             </div>
                                          </div>
                                          <p>Increase <br/>In Impressions</p>
                                       </div>
                                    </div>
                                 </div>
                  </div>

                  <div className="conclusion-contianer">
                     <div className="container">
                        <h2>Conclusion</h2>
                        <p>Want to increase your ROAS from what you have right now? It is possible and we’ve shown you how!</p>
                        <p>
                        In case your plate is full and you’re looking for a trusted pair of hands to create a bespoke social media
strategy for your business, you can get in touch with us at <a href="mailto:letstalk@cueforgood.com" className="focus-visible">letstalk@cueforgood.com</a>.
                        </p>
                        <p>We’d love to help you out.</p>
                     </div>
                  </div>

                    <div id="scroll-to" className="case-scroll">
                       <a href="#" title="Scroll To Top" className="focus-visible"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                    </div>
                  </div>
               </div>
                <Clutch />

            </Layout>
        )
    }
}